import React, { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

import Nav from './Nav';
import Basket from './Cart';
import Burger from './Burger';
import Column from './Column';
import UserArea from './UserArea';
import { headerVariants } from './variants';
import { Logo } from '..';
import type { HeaderVariantProps } from './variants';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User } from '@expanzi/api-types';
import { Button } from '@expanzi/ui-library';
import { useTranslation } from '@/lib/i18n.ts';
import { PersonalizationSelect } from '@/components/ui';

export type HeaderProps = HeaderVariantProps & {
    referral?: string;
    currency: string;
    deliveryCountry: string;
    user: User | null;
};

const Header: React.FC<HeaderProps> = (props) => {
    const { color = 'dark', user, referral, currency, deliveryCountry } = props;
    const { t, locale } = useTranslation();

    const [open, setOpen] = useState(false);
    const [showHamburger, setShowHamburger] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setShowHamburger(window.innerWidth <= 1280);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return (
        <header className={cn(headerVariants({ color, open }))}>
            <div className="mx-auto flex w-full shrink-0 items-center px-6 py-8 xl:container xl:py-4">
                <Column className="justify-start">
                    <Logo href="/" />
                </Column>
                <Column className="justify-center max-xl:hidden xl:basis-2/4">
                    <Nav />
                </Column>
                <Column className="shrink-0 justify-end gap-x-5">
                    <div className="inline-flex divide-x max-xl:hidden">
                        {!showHamburger && (
                            <PersonalizationSelect
                                locale={locale}
                                currency={currency}
                                deliveryCountry={deliveryCountry}
                            />
                        )}
                    </div>
                    <div className="size-14">
                        <Basket href="/cart" referral={referral} loggedIn={Boolean(user)} />
                    </div>
                    <Button
                        variant="outline"
                        size="md"
                        rounded
                        className="w-full border-white text-white hover:border-white hover:bg-white max-xl:hidden"
                        href={import.meta.env.PUBLIC_WEB_URL}
                    >
                        {t('web')}
                    </Button>
                    <UserArea className="shrink-0 max-xl:hidden" user={user} referral={referral} color={color} />
                    <Burger open={open} onClick={() => setOpen((state) => !state)} />
                </Column>
            </div>
            {open && (
                <div className="flex grow flex-col overflow-y-auto px-4 py-12 xl:hidden">
                    <Nav vertical />
                    <div className="mx-auto mb-10 mt-auto py-3">
                        {showHamburger && (
                            <PersonalizationSelect
                                locale={locale}
                                currency={currency}
                                deliveryCountry={deliveryCountry}
                            />
                        )}
                    </div>
                    <Button
                        variant="outline"
                        size="md"
                        rounded
                        className="mx-auto mb-2.5 w-full border-white text-white hover:bg-white md:w-36"
                        href={import.meta.env.PUBLIC_OFFICE_URL}
                    >
                        {t('web')}
                    </Button>
                    <UserArea className="mx-auto" user={user} referral={referral} color={color} />
                </div>
            )}
        </header>
    );
};

export default Header;
