import React, { useState } from 'react';

import { PersonalInformationForm, Text } from '@/components/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
    CompanyDetails_jsonld_client_read_companyDetails_read_address_read as Company,
    Invoice_jsonld_invoice_write,
    User_jsonld_user_read_file_read_dealer_read_client_read_storeman_read_company_details_read_address_read as User,
} from '@expanzi/api-types';
import { invoiceSchema } from '../cart/validations';
import { useTranslation } from '@/lib/i18n';
import type { CountryCodelist } from '@/lib/resources/codelist';
import Alert from '../alert/Alert';
import { request } from '@/lib/api';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/store';
import ConsentForm from '../consent-form/ConsentForm';
import type { CompanyDetails_jsonld_invoice_write } from '@expanzi/api-types';

import { ENTITY_TYPES } from '@/components/ui/profile/types.ts';
import { Button, DialogContent, useDialog } from '@expanzi/ui-library';
import { validateICO } from '@/lib/form.ts';
import useIcoConfirmDialog from '@/components/ui/icoConfirmDialog/IcoConfirmDialog.tsx';

interface Props {
    token?: string | null;
    countries: CountryCodelist[];
    user: User | null;
}

export interface PersonalInformationData extends Invoice_jsonld_invoice_write {
    gdprConsent: boolean;
    orderConsent: boolean;
    sameDeliveryAndBilling: string;
    invoiceWanted: boolean;
    billingDetails: CompanyDetails_jsonld_invoice_write;
    deliveryDetails: CompanyDetails_jsonld_invoice_write;
}

const PersonalInformationDialog: React.FC<Props> = (props) => {
    const { countries = [], user, token } = props;

    const { t } = useTranslation();
    const { Dialog } = useDialog({ defaultOpen: true });

    const [loading, setLoading] = useState(false);
    const { open, ConfirmDialog } = useIcoConfirmDialog({ onClose: () => setLoading(false) });

    const formMethods = useForm<PersonalInformationData>({
        resolver: zodResolver(invoiceSchema),
        defaultValues: {
            sameDeliveryAndBilling: 'none',
            gdprConsent: true,
            orderConsent: true,
            invoiceWanted: false,
            billingDetails: {
                contactPersonEmail: user?.email,
                contactPersonLastName: user?.profile?.lastName,
                contactPersonFirstName: user?.profile?.firstName,
                ico: null,
                dic: null,
                name: null,
            },
            deliveryDetails: {
                contactPersonEmail: user?.email,
                contactPersonLastName: user?.profile?.lastName,
                contactPersonFirstName: user?.profile?.firstName,
            },
        },
        mode: 'onBlur',
    });

    const prepareData = (data: PersonalInformationData): Invoice_jsonld_invoice_write => {
        data.billingDetails.entityType = data.invoiceWanted ? ENTITY_TYPES.COMPANY : ENTITY_TYPES.PERSON;
        if (data.sameDeliveryAndBilling) {
            data.deliveryDetails = {
                ...data.billingDetails,
            };
        }
        if (data.invoiceWanted) {
            data.deliveryDetails = {
                ...data.deliveryDetails,
                name: data.billingDetails.name,
                ico: data.billingDetails.ico,
                dic: data.billingDetails.dic,
                entityType: data.billingDetails.entityType,
            };
        }

        if (!data.invoiceWanted) {
            data.billingDetails.ico = null;
            data.deliveryDetails.ico = null;
        }

        return data;
    };

    const { mutate, isError, isPending, isSuccess } = useMutation<unknown, unknown, PersonalInformationData>(
        {
            mutationFn: async (rawData) => {
                const data = prepareData(rawData);

                await request(`/api/users/${user?.id}`, {
                    method: 'PATCH',
                    token,
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                    body: {
                        profile: {
                            lastName: data?.billingDetails?.contactPersonLastName,
                            firstName: data?.billingDetails?.contactPersonFirstName,
                            phoneNumber: data?.billingDetails?.contactPersonPhoneNumber,
                        },
                    },
                });

                const res = await request(`/api/users/${user?.id}/client`, {
                    method: 'POST',
                    token,
                    body: data,
                });
            },
            onSuccess: () => {
                window.location.href = new URL(window.location.href).toString();
            },
        },
        queryClient,
    );

    const onSubmit = async (data: PersonalInformationData) => {
        setLoading(true);
        if (data.billingDetails.ico && data.billingDetails.address?.country) {
            const res = await validateICO(data.billingDetails.ico, data.billingDetails.address?.country);
            if (!res) {
                open();
                return;
            }
        }
        mutate(data);
        setLoading(false);
    };

    return (
        <>
            <Dialog>
                <DialogContent className="h-full max-h-[86vh] min-w-[80%]">
                    <FormProvider {...formMethods}>
                        <form
                            className="flex h-full flex-col gap-6 py-6 sm:py-0"
                            onSubmit={formMethods.handleSubmit(onSubmit)}
                        >
                            {isError && <Alert>{t('register.finalize_error')}</Alert>}
                            <PersonalInformationForm
                                user={user}
                                allCountries={countries}
                                deliveryCountries={countries.filter((country) => country.deliveryAllowed)}
                            />
                            <ConsentForm defaultChecked className="hidden" />
                            <div className="flex flex-1 items-center justify-between gap-4">
                                <Text.Body>{t('fields_required')}</Text.Body>
                                <Button
                                    rounded
                                    size="lg"
                                    disabled={isSuccess}
                                    loading={isPending || loading}
                                    type="submit"
                                >
                                    {t('submit')}
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </DialogContent>
            </Dialog>
            <ConfirmDialog
                onSubmit={() => mutate(formMethods.getValues())}
                ico={formMethods.watch('billingDetails.ico') || ''}
            />
        </>
    );
};

export default PersonalInformationDialog;
