import React from 'react';
import { useTranslation } from '@/lib/i18n.ts';
import PasswordChangeForm from '@/components/ui/password-change-form/PasswordChangeForm';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User } from '@expanzi/api-types';
import { Lock, Trash2 } from 'lucide-react';
import { DeleteAccountDialog } from '@/components/ui/profile/DeleteAccountDialog.tsx';
import { isDealer } from '@/lib/role.ts';
import { Button, DialogContent, DialogTrigger, Text, useDialog } from '@expanzi/ui-library';

type Props = {
    token?: string | null;
    user: User | null;
};

const ProfileSettings: React.FC<Props> = ({ token, user }) => {
    const { t } = useTranslation();
    const { Dialog } = useDialog();

    return (
        <div className="grid gap-6 lg:grid-cols-2">
            <div className="mt-6 flex flex-col gap-y-8 rounded-xl bg-white p-8">
                <Text.Body>{t('password_change.description')}</Text.Body>
                <ChangePasswordDialog user={user} token={token} />
            </div>
            {!isDealer(user) && (
                <div className="mt-6 flex flex-col gap-y-8 rounded-xl bg-white p-8">
                    <Text.Body as="span">{t('delete_profile.description')} </Text.Body>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button variant="outline" color="red" size="md" rounded className="mt-auto md:w-fit">
                                <div className="flex h-full w-full items-center gap-2 text-inherit">
                                    <Text.Body bold>{t('delete_profile.deletion')}</Text.Body>
                                    <Trash2 />
                                </div>
                            </Button>
                        </DialogTrigger>
                        <DialogContent>
                            <DeleteAccountDialog token={token} user={user} />
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </div>
    );
};

const ChangePasswordDialog = (props: Props) => {
    const { t } = useTranslation();
    const { Dialog } = useDialog();

    const { token, user } = props;

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="default" color="dark" size="md" rounded className="mt-auto md:w-fit">
                    <div className="flex h-full w-full items-center gap-3 text-inherit">
                        <Text.Body bold>{t('password_change.cta')}</Text.Body>
                        <Lock />
                    </div>
                </Button>
            </DialogTrigger>
            <DialogContent className="overflow-y-scroll">
                <PasswordChangeForm token={token} user={user} />
            </DialogContent>
        </Dialog>
    );
};

export default ProfileSettings;
