import { request } from '@/lib/api';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User } from '@expanzi/api-types';

export async function userMe(token?: string | null): Promise<User | null> {
    if (token) {
        const { data, ok } = await request('/api/users/me', { token });
        if (ok) {
            return data;
        }
    }
    return null;
}
