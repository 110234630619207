'use client';

import React, { useEffect, useRef } from 'react';

import { signIn } from 'auth-astro/client';

import Loader from '@/components/ui/loader/Loader';
import { resetCartDetails } from '@/lib/cart.ts';
import { DialogContent, DialogTitle, useDialog } from '@expanzi/ui-library';

interface Props {
    token?: string;
}

export default function AutoLogin(props: Props) {
    const { token } = props;

    const inProgressRef = useRef(false);
    const { Dialog } = useDialog();

    async function autoLogin() {
        await signIn('credentials', {
            redirect: false,
            token,
        });
        resetCartDetails();
        window.location.href = '/';
        return;
    }

    useEffect(() => {
        if (inProgressRef.current) {
            return;
        }
        inProgressRef.current = true;
        autoLogin();
    }, []);

    return (
        <Dialog open>
            <DialogContent>
                <div className="relative mt-4">
                    <Loader isVisible />
                    <DialogTitle>&nbsp;</DialogTitle>
                </div>
            </DialogContent>
        </Dialog>
    );
}
