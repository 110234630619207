import React from 'react';

import PasswordChangeForm from './PasswordChangeForm';
import { DialogContent, useDialog } from '@expanzi/ui-library';

type Props = {
    token?: string | null;
};
const ResetPasswordDialog: React.FC<Props> = ({ token }) => {
    const { Dialog } = useDialog();
    return (
        <Dialog open={true}>
            <DialogContent
                onInteractOutside={() => {
                    window.location.href = '/';
                }}
            >
                <PasswordChangeForm token={token} resetPassword />
            </DialogContent>
        </Dialog>
    );
};

export default ResetPasswordDialog;
