import React, { useEffect, useState } from 'react';
import { ChevronDown } from '@/svg';
import Text from '../text/Text';
import { Button, Dropdown, Flag, Popover, PopoverContent, PopoverTrigger, Select } from '@expanzi/ui-library';
import { useQueries } from '@tanstack/react-query';
import { getCurrenciesFormated, getDeliveryCountriesFormated, getLanguagesFormated } from '@/loaders/codelist.ts';
import { queryClient } from '@/lib/store.ts';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from '@/lib/i18n.ts';
import { COUNTRY_PARAM, CURRENCY_PARAM, LOCALE_PARAM } from '@/constants/params.ts';

type Props = {
    locale: string;
    currency: string;
    deliveryCountry: string;
    className?: string;
    hidden?: boolean;
};

export default function PersonalizationSelect(props: Props) {
    const { locale, currency, deliveryCountry } = props;
    const { t } = useTranslation();

    const [countriesQuery, languagesQuery, currencyQuery] = useQueries(
        {
            queries: [
                { queryKey: ['deliveryCountries'], queryFn: () => getDeliveryCountriesFormated(locale) },
                { queryKey: ['languages'], queryFn: () => getLanguagesFormated() },
                { queryKey: ['currencies'], queryFn: () => getCurrenciesFormated() },
            ],
        },
        queryClient,
    );

    const formMethods = useForm({
        defaultValues: {
            country: deliveryCountry.toUpperCase(),
            language: locale.toUpperCase(),
            currency: currency,
        },
    });

    const setSelected = (data: { country: string; language: string; currency: string }) => {
        const url = new URL(window.location.href);

        url.searchParams.set(COUNTRY_PARAM, data.country);
        url.searchParams.set(CURRENCY_PARAM, data.currency);
        url.searchParams.set(LOCALE_PARAM, data.language);

        window.location.href = url.toString();
    };

    const [showCountryPopover, setShowCountryPopover] = useState(false);
    const [showPopover, setShowPopover] = useState(false);

    useEffect(() => {
        const firstSession = !localStorage.getItem('firstSession');

        if (firstSession) {
            setShowCountryPopover(true);
            setShowPopover(true);
        }
    }, []);

    const handleOpenChange = (open: boolean) => {
        if (!showCountryPopover && !open) {
            setShowPopover(false);
        }
    };

    const handleCountryPopover = (showCountrySelect?: boolean) => {
        localStorage.setItem('firstSession', 'true');
        if (Boolean(showCountrySelect)) {
            setShowCountryPopover(false);
        } else {
            setShowPopover(false);
        }
    };

    const openSelectPopover = () => {
        if (!localStorage.getItem('firstSession')) {
            localStorage.setItem('firstSession', 'true');
        }
        setShowPopover(true);
        setShowCountryPopover(false);
    };

    return (
        <Popover open={showPopover} onOpenChange={handleOpenChange}>
            <PopoverTrigger onClick={openSelectPopover}>
                <Text.Body className="flex max-h-5 flex-row divide-x font-medium" as="div">
                    <div className="flex flex-row items-center gap-3 pr-2">
                        <Flag countryCode={deliveryCountry} />
                        <Text.Body>{locale.toUpperCase()}</Text.Body>
                    </div>
                    <div className="flex flex-row items-center gap-1 pl-2">
                        <Text.Body>{currency}</Text.Body>
                        <ChevronDown className="w-2" />
                    </div>
                </Text.Body>
            </PopoverTrigger>
            <PopoverContent className="z-10 min-w-0">
                {showCountryPopover ? (
                    <div>
                        <Text.Body>
                            {t('looksLike')}{' '}
                            <span className="font-bold">
                                {countriesQuery.data?.find((country) => country.id === deliveryCountry)?.label}
                            </span>
                            . {t('setDeliveryCountry')}
                        </Text.Body>
                        <div className="mt-6 flex w-full flex-row flex-wrap gap-2">
                            <Button rounded className="flex-1" type="button" onClick={() => handleCountryPopover()}>
                                {t('yes')}
                            </Button>
                            <Button
                                variant="outline"
                                rounded
                                className="flex-1"
                                onClick={() => handleCountryPopover(true)}
                            >
                                {t('changeCountry')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(setSelected)}>
                            <Dropdown
                                flag
                                options={countriesQuery.data || []}
                                label={t('deliveryCountry')}
                                id="country"
                                {...formMethods.register('country')}
                            />
                            <Dropdown
                                options={languagesQuery.data || []}
                                label={t('language')}
                                id="language"
                                {...formMethods.register('language')}
                            />
                            <Dropdown
                                label={t('currency')}
                                id="currency"
                                options={currencyQuery.data || []}
                                {...formMethods.register('currency')}
                            />
                            <Button rounded variant="default" color="dark" className="w-full">
                                {t('save')}
                            </Button>
                        </form>
                    </FormProvider>
                )}
            </PopoverContent>
        </Popover>
    );
}
